import allerRetour from "../../images/icons/icon_aller_retour.svg";
import allerSimple from "../../images/icons/icon_aller_simple.svg";
import boucle from "../../images/icons/icon_boucle.svg";

export default function hikeType(typeId: number) {
  enum JourneyType {
    PointToPoint = 0,
    OutAndback = 1,
    Loop = 2,
  }

  let img: string = '';
  let title: string = '';
  let alt: string = '';

  if (typeof typeId === "number") {
    switch (typeId) {
      case JourneyType.PointToPoint:
          img = allerSimple;
          title = 'Aller-simple';
          alt = 'Randonnée de type aller-simple';
        break;

      case JourneyType.OutAndback:
          img = allerRetour;
          title = 'Aller-retour';
          alt = 'Randonnée de type aller-retour';
        break;

      case JourneyType.Loop:
          img = boucle;
          title = 'Boucle';
          alt = 'Randonnée de type boucle';
        break;

      default:
        title = 'Inconnu';
        break;
    }
  }

  return { img, title, alt };
}
