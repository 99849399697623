import React from "react";
import AccommodationsCarousel from "../../Accommodations/AccommodationsCarousel";
import { Container, Row, Col } from "react-bootstrap";

export default function HikeAccommodations(props: { slug?: string | null, title?: string | null }) {
  const { slug, title } = props;
  const setCarouselSettings = {
    responsive: {
      breakpoint2400: {
        slidesToShow: 2.4,
      },
      breakpoint1500: {
        slidesToShow: 2.2,
      },
      breakpoint1300: {
        slidesToShow: 1.8,
      },
      breakpoint1024: {
        slidesToShow: 1.5,
      },
      breakpoint768: {
        slidesToShow: 1.8,
      },
      breakpoint480: {
        slidesToShow: 1.4,
      },
    },
    className: "center",
    centerPadding: "60px",
    slidesToShow: 4.2,
    slidesToScroll: 3,
    infinite: false,
  };

  return (
    <Col>
      <Container fluid>
        <Row>
          <Col>
            <h2>Logements à proximité</h2>
            <p>Où loger autour de la randonnée {title} ?</p>
            <AccommodationsCarousel
              slug={slug}
              carouselSettings={setCarouselSettings}
            />
            <p>Voici quelques logements, gîtes et locations de vacances à proximité de la balade.</p>
          </Col>
        </Row>
      </Container>
    </Col>
  );
}
