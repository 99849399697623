// extracted by mini-css-extract-plugin
export var banner__bgColor = "HikeCover-module--banner__bgColor--6f0b4";
export var breadcrumb = "HikeCover-module--breadcrumb--9ac9e";
export var cover = "HikeCover-module--cover--4c1c3";
export var destinationTitle = "HikeCover-module--destination-title--77688";
export var heroBanner = "HikeCover-module--hero-banner--5ba59";
export var hikeCover = "HikeCover-module--hikeCover--7b6bf";
export var lightBanner = "HikeCover-module--light-banner--33ef9";
export var sectionBanner = "HikeCover-module--section-banner--f4083";
export var title = "HikeCover-module--title--83efe";
export var titleBanner = "HikeCover-module--title-banner--a6266";