import React, { ReactNode } from "react";
import { getSrc, IGatsbyImageData} from "gatsby-plugin-image";
import Lightbox from "react-18-image-lightbox";
import ObfuscationLink from "../../seo/ObfuscationLink";
import FormGallery from "./FormGallery";

// Documentation : 
// React Image Lightbox - https://frontend-collective.github.io/react-image-lightbox/
// Potentially to replace by https://www.npmjs.com/package/react-photoswipe-gallery 

interface States {
  photoIndex: number,
  isOpen: boolean
}
interface Props { 
  data: Queries.OneHikeFragment,
  children?: ReactNode
}
interface ImgDescription {
  ig_handle: string,
  url: string
}

interface GalleryImage {
    readonly description: string | null;
    readonly gatsbyImageData: IGatsbyImageData | null;
    readonly id: string;
    readonly file: {
        readonly fileName: string | null;
        readonly url: string | null;
    } | null;
}

export default class Gallery extends React.Component<Props,States> {
    constructor(protected data: Props) {
        super(data);

        // Initialisation des variables de la galerie d'images
        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }

    render() {
      const { photoIndex, isOpen } = this.state;
      const coverImage = this.props.data.cover;
      const galleryImages = this.props.data.gallery;

      // Création d'un nouvel array avec la cover + la gallerie d'image
      let images:GalleryImage[] = []; 

      if(coverImage) {
        images.push(coverImage);
      }

      if(galleryImages) {
        galleryImages.forEach(galleryImage => {
          if(galleryImage) {
            images.push(galleryImage)
          }
        });
  
        const imagesLength = images.length;
  
        let imgDescString: string | null = null;
        let imgDescJson: ImgDescription | null = null;
        let profileUrl: string | null = null;
        let instagramHandle: string | null = null;
  
        const hikeTitle = this.props.data?.title;
        let captionsPhotographer: (JSX.Element | string)[] = []; // Old type [(JSX.Element | string), [(string | null), string]][] = [];
        let captionsHike: (JSX.Element | string)[] = [];
        let formGallery: JSX.Element[] = [];

        let oneCaptionPhotographer: JSX.Element | string = ''; // Push result even if there is no name
        let oneCaptionHike: JSX.Element | string = ''; // Push result even if there is no hike related
  
        let imgName: string | null = null;
        let imgId = '';

        let imageData:IGatsbyImageData[] = []
  
  
        // Création des arrays pour le titre des randonnées ainsi que pour le crédit des photos (instagrameurs)
        images.forEach(image => {
  
          imgDescString = image?.description;
          imgDescJson = imgDescString ? JSON.parse(imgDescString) : null
          profileUrl = imgDescJson?.url ? imgDescJson.url.replace( /</g, '\\u003c') : null;
          instagramHandle = imgDescJson?.ig_handle ? imgDescJson.ig_handle.replace( /</g, '\\u003c') : null;
  
          imgName = image.file ? image.file?.fileName : null;
          imgId = image.id;
  
          oneCaptionPhotographer = instagramHandle ? <p>Photographié par 👉 <ObfuscationLink encodeUrl={profileUrl}>{`@${instagramHandle}`}</ObfuscationLink></p> : '';
          oneCaptionHike = hikeTitle ? `Randonnée ‣ ${hikeTitle}` : '';

          if(image.gatsbyImageData) {
            captionsPhotographer.push(oneCaptionPhotographer)
            captionsHike.push(oneCaptionHike)
            imageData.push(image.gatsbyImageData)
            formGallery.push(<FormGallery instagramHandle={`@${instagramHandle}`} imgId={imgId} imgName={imgName} contexte={oneCaptionHike} />)
          }

        });
  
        // Appel de la gallerie (Lighbox) mettant en avant la galerie d'images
        // this.props.component récupère le composant cliquable ouvrant la gallerie
        const getImage = getSrc(imageData[photoIndex]);
        const getNextImage = getSrc(imageData[(photoIndex + 1) % imagesLength]);
        const getPreviousImage = getSrc(imageData[(photoIndex + imagesLength - 1) % imagesLength]);

        if(typeof getImage !== 'undefined' && typeof getNextImage !== 'undefined' && typeof getPreviousImage !== 'undefined') {

          return (
            <div>
              <div onClick={() => this.setState({ isOpen: true })} role="button" tabIndex={0}>
                {this.props.children}
              </div>
                {isOpen && (
                  <Lightbox 
                      mainSrc={getImage}
                      nextSrc={getNextImage}
                      prevSrc={getPreviousImage}
                      onCloseRequest={() => this.setState({ isOpen: false })}
                      onMovePrevRequest={() =>
                      this.setState({
                          photoIndex: (photoIndex + imagesLength - 1) % imagesLength,
                      })
                      }
                      onMoveNextRequest={() =>
                      this.setState({
                          photoIndex: (photoIndex + 1) % imagesLength,
                      })
                      }
                      imageCaption={captionsPhotographer[photoIndex]}
                      imageTitle={captionsHike[photoIndex]}
                      enableZoom={false}
                      imagePadding={100}
                      toolbarButtons={[formGallery[photoIndex]]}
                  />
                )}
            </div>
          );
        }
        
      }
      return this.props.children;
      
    }
}  
