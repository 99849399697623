import autorise from "../../images/icons/icon_chien_autorise.svg";
import deconseille from "../../images/icons/icon_chien_deconseille.svg";
import interdit from "../../images/icons/icon_chien_interdit.svg";
import laisse from "../../images/icons/icon_chien_laisse.svg";

export default function hikeDogs(dogsId?: number | null) {
  enum DogsRules {
    Unknown = -1,
    Prohibited = 0,
    NotRecommended = 1,
    OnALeash = 2,
    Authorized = 3,
  }

  let img: string = "";
  let title: string = "";
  let alt: string = "";

  if (typeof dogsId === "number") {
    switch (dogsId) {
      case DogsRules.Unknown:
        title = "Inconnu";
        break;

      case DogsRules.Prohibited:
        img = interdit;
        title = "Interdit";
        alt = "Chien interdit sur le sentier de cette randonnée";
        break;

      case DogsRules.NotRecommended:
        img = deconseille;
        title = "Déconseillé";
        alt = "Chien déconseillé sur le sentier de cette randonnée";
        break;

      case DogsRules.OnALeash:
        img = laisse;
        title = "En laisse";
        alt = "Chien à tenir en laisse sur cette randonnée";
        break;

      case DogsRules.Authorized:
        img = autorise;
        title = "Autorisé";
        alt = "Chien autorisé sur cette randonnée";
        break;

      default:
        title = "Inconnu";
        break;
    }
  }

  return { img, title, alt };
}
