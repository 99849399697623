import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { PageProps } from "gatsby";

import ShareModal from "../ShareModal";
import ObfuscationLink from "../../seo/ObfuscationLink";
import Gallery from "../../UI/Gallery/Gallery";

// SVG
import photos_svg from "../../../images/icons/icon_photo.svg";
import car_svg from "../../../images/icons/icon_car.svg";
import accommodations_svg from "../../../images/icons/icon_accomodation.svg";
import share from "../../../images/icons/icon_share.svg";

// Styles
import * as styles from "./HikeBannerButtons.module.scss";

export default function HikeBannerButtons(props: {
  location: PageProps["location"];
  hike: Queries.OneHikeFragment | null;
}) {
  const [showModal, setShowModal] = useState(false);
  const hike = props.hike;

  // Gallery
  function GalleryButton() {
    if (hike?.gallery) {
      return (
        <Col md="3" sm="3" xs="3" className={styles.buttonIcon}>
          <Gallery data={hike}>
            <div
              className={` ${styles.circleIcon} d-flex justify-content-center`}
            >
              <img
                src={photos_svg}
                alt="Galerie d'images"
                className={styles.icon}
              />
            </div>
            <p className="my-0">Photos</p>
          </Gallery>
        </Col>
      );
    }
    return null;
  }

  // Affiliation
  // Destination variables PARK or URBANAREA
  const destAffiliateLink = () => {
    if (hike?.park) {
      if (hike.park.bnbAffiliationLink) {
        return hike.park.bnbAffiliationLink?.bnbAffiliationLink;
      }
    } else if (hike?.hikeUrbanArea) {
      if (hike.hikeUrbanArea[0]?.bnbAffiliationLink) {
        return hike.hikeUrbanArea[0]?.bnbAffiliationLink?.bnbAffiliationLink;
      }
    }
    return null;
  };
  const AccommodationButton = (props: { link: string | null }) => {
    if (props.link) {
      return (
        <Col md="3" sm="3" xs="3" className={styles.buttonIcon}>
          <ObfuscationLink encodeUrl={props.link}>
            <>
              <div
                className={`${styles.circleIcon} d-flex justify-content-center`}
              >
                <img
                  src={accommodations_svg}
                  alt="Trouver un logement vers le lieu de la randonnée"
                  className={styles.icon}
                />
              </div>
              <p className="my-0">Se loger</p>
            </>
          </ObfuscationLink>
        </Col>
      );
    }
    return null;
  };

  // Parking
  interface ParkingCoordinatesProps {
    coordinates: {
      parkingLatitude: number | null | undefined;
      parkingLongitude: number | null | undefined;
    };
  }

  const parkingCoordinates = {
    parkingLatitude: hike?.parkingLocation?.lat
      ? hike.parkingLocation.lat
      : null,
    parkingLongitude: hike?.parkingLocation?.lon
      ? hike.parkingLocation.lon
      : null,
  };

  const GoToButton = (props: ParkingCoordinatesProps) => {
    if (
      props.coordinates.parkingLatitude &&
      props.coordinates.parkingLongitude
    ) {
      return (
        <Col md="3" sm="3" xs="3" className={styles.buttonIcon}>
          <ObfuscationLink
            encodeUrl={`https://maps.google.com/?q=${props.coordinates.parkingLatitude},${props.coordinates.parkingLongitude}`}
          >
            <>
              <div
                className={`${styles.circleIcon} d-flex justify-content-center`}
              >
                <img
                  src={car_svg}
                  alt="Se rendre sur le point de départ de la randonnée"
                  className={styles.icon}
                />
              </div>
              <p className="my-0">S'y rendre</p>
            </>
          </ObfuscationLink>
        </Col>
      );
    }
    return null;
  };

  // Share modal
  const ShareButton = () => {
    const openHandler = () => {
      setShowModal(true);
    };
    const closeHandler = () => {
      setShowModal(false);
    };

    // Get current URL
    const siteName = `| Rheaparks`;
    const hikeTitle = hike?.title ? `→ ` + hike.title : "";

    return (
      <Col md="3" sm="3" xs="3" className={styles.buttonIcon}>
        <div onClick={openHandler} role="button" tabIndex={0}>
          <div className={`${styles.circleIcon} `}>
            <img
              src={share}
              alt="Partager la randonnée"
              className={styles.icon}
            />
          </div>
          <p className="my-0">Partager</p>
        </div>
        <ShareModal
          location={props.location}
          facebookTitle={`Une belle randonnée à faire ${hikeTitle} ${siteName}`}
          twitterTitle={`Une belle randonnée à faire ${hikeTitle} ${siteName}`}
          emailSubject={`Randonnée ${hikeTitle} ${siteName}`}
          emailBody={`Je viens de découvrir une belle randonnée à faire :`}
          linkedInTitle={`Une randonnée incontournable ${hikeTitle} ${siteName}`}
          closeModal={closeHandler}
          showModal={showModal}
        />
      </Col>
    );
  };

  return (
    <Row className="g-0">
      <Col md="12">
        <Row className="bg-mint gx-0">
          <GalleryButton />

          <GoToButton coordinates={parkingCoordinates} />

          <AccommodationButton link={destAffiliateLink()} />

          <ShareButton />
        </Row>
      </Col>
    </Row>
  );
}
