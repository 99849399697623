import React from "react";
import { Row, Col } from 'react-bootstrap';
import ObfuscationLink from "../../seo/ObfuscationLink";
import ReactMarkdown from 'react-markdown';

import car from '../../../images/icons/icon_car_light.svg';

interface Props {
    parkingCoordinates: {
        parkingLatitude: number | null,
        parkingLongitude: number | null   
    },
    parkingLabel: string | null,
    title: string | null,
    className: string
}

export default function Parking(props: Props) {
    const parkingLabel = props.parkingLabel;
    const parkingCoordinates = props.parkingCoordinates;
    const hikeTitle = props.title ? <span className='infoTitleMd'>- {props.title}</span> : null;

    const ParkingLabel = (props: { parkingLabel: Props['parkingLabel']}) => {
        if(props.parkingLabel) {
          return(
            <ReactMarkdown>{props.parkingLabel}</ReactMarkdown>
          )
        }
        return null
    }

    const ParkingMap = (props: { parkingCoordinates: Props['parkingCoordinates'], className: Props['className'] }) => {
        if(parkingCoordinates.parkingLatitude && parkingCoordinates.parkingLongitude) {
            const googleMapsUrl = `https://maps.google.com/?q=${props.parkingCoordinates.parkingLatitude},${props.parkingCoordinates.parkingLongitude}` 
            return (
                <ObfuscationLink encodeUrl={googleMapsUrl} className={props.className}>
                    <>
                        <img src={car} alt="Se rendre sur le point de départ de la randonnée" className='icon me-3'/>
                        <span>S'y rendre en voiture</span>
                    </>
                </ObfuscationLink>
            )
        } 
        return null
    }

    if(parkingLabel || (parkingCoordinates.parkingLatitude && parkingCoordinates.parkingLongitude)) {
        return (
            <Col md="12" className='pb-3'>
                <h2>Parking {hikeTitle}</h2>
                <ParkingLabel parkingLabel={parkingLabel} />
                <Row className="gx-1">
                    <ParkingMap parkingCoordinates={parkingCoordinates} className={props.className} />
                </Row>
            </Col>
        )
    }
    return null
}






