import React from "react";
import { Col } from "react-bootstrap";
import ObfuscationLink from "../../seo/ObfuscationLink";
import ProjectButton from "../../UI/ProjectButton";

export default function HikeImprovement() {
  return (
    <Col md="12" className={`pb-computer`}>
      <h2>Améliorer cette randonnée</h2>
      <p>
        Un conseil ou une remarque (GPX, parking, images…) sur cette randonnée
        pédestre ? Merci de nous le transmettre, nous le prendrons en compte !
        🙂
      </p>
      <ObfuscationLink encodeUrl="mailto:contact@rheaparks.com">
        <ProjectButton className="btn-info btn-lg w-100 mt-2">
          <span>Envoyer un retour</span>
        </ProjectButton>
      </ObfuscationLink>
    </Col>
  );
}
