// extracted by mini-css-extract-plugin
export var absoluteDifficulty = "HikeInfo-module--absoluteDifficulty--11e0d";
export var bgDarkGreen = "HikeInfo-module--bg-dark-green--f151d";
export var bgDarkGreen01 = "HikeInfo-module--bg-dark-green-01--266fe";
export var bgGradientGreen = "HikeInfo-module--bg-gradient-green--019ca";
export var bgGradientLyellow = "HikeInfo-module--bgGradientLyellow--80f7c";
export var bgGreen = "HikeInfo-module--bg-green--c39f8";
export var bgGrey = "HikeInfo-module--bg-grey--03bbe";
export var bgLightBlue = "HikeInfo-module--bg-light-blue--27eed";
export var bgLightGreen = "HikeInfo-module--bg-light-green--bdf02";
export var bgLightGreenColor = "HikeInfo-module--bg-light-green-color--edf03";
export var bgLightGrey = "HikeInfo-module--bg-light-grey--63d46";
export var bgLightYellow = "HikeInfo-module--bg-light-yellow--0a00b";
export var bgMint = "HikeInfo-module--bg-mint--da731";
export var bgOrange = "HikeInfo-module--bg-orange--d6829";
export var difficulty = "HikeInfo-module--difficulty--2e8a1";
export var easyHike = "HikeInfo-module--easy-hike--ce241";
export var green = "HikeInfo-module--green--98504";
export var grey = "HikeInfo-module--grey--1f87e";
export var hardHike = "HikeInfo-module--hard-hike--adff5";
export var icon = "HikeInfo-module--icon--79034";
export var info = "HikeInfo-module--info--447e9";
export var info__title = "HikeInfo-module--info__title--dfb65";
export var lightBlue = "HikeInfo-module--light-blue--f1898";
export var lightGreen = "HikeInfo-module--light-green--f8cb7";
export var lightYellow = "HikeInfo-module--light-yellow--9506b";
export var mediumHike = "HikeInfo-module--medium-hike--7536c";
export var mint = "HikeInfo-module--mint--35cd6";
export var orange = "HikeInfo-module--orange--2870c";
export var unknownHike = "HikeInfo-module--unknown-hike--4dc62";
export var veryHardHike = "HikeInfo-module--very-hard-hike--f110e";