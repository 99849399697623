import React from 'react';
import { Popover } from 'react-bootstrap';
import { Link } from 'gatsby';

import unavoidable from '../../images/icons/icon_rank_1.svg';
import remarquable from '../../images/icons/icon_rank_2.svg';
import classique from '../../images/icons/icon_rank_3.svg';


export default function hikeRankOverlay() {
    return (
        <>
            <Popover.Header as="p">
                <b>Classements</b>
            </Popover.Header>
            <Popover.Body>
                <p>Ordre de classement des randonnées :</p>
                <div>
                    <div>
                        <img src={unavoidable} alt={"Randonnée incontournable à voir au cœur du parc"} className='icon me-2 mb-2'/>
                        <span>Incontournable</span>
                    </div>
                    <div>
                        <img src={remarquable} alt={"Randonnée remarquable à voir au cœur du parc"} className='icon me-2 mb-2'/>
                        <span>Remarquable</span>
                    </div>
                    <div>
                        <img src={classique} alt={"Randonnée classique à voir au cœur du parc"} className='icon me-2 mb-2'/>
                        <span>Classique</span>
                    </div>
                </div>
                <p><small><Link to="/faq/?a=3#classement">Comment est mesuré le classement ?</Link></small></p>
            </Popover.Body>
        </>
    );
}