import React, { ReactNode, useState } from "react";
import { Collapse, Button } from 'react-bootstrap';
import isMobile from "../Layout/IsMobile";

import arrow from '../../images/icons/icon_arrow_light.svg';

import * as styles from "./HikeAltAccordion.module.scss";

type Props = {
  children: ReactNode | null
}

  export default function AlternativeAccordion({children}: Props) {
    const [open, setOpen] = useState(true);

    const ArrowButton = () => {
      if(open) {
        return <img src={arrow} alt="Flèche des randonnées alternatives" className="icon rotate-90" />
      } 
        return <img src={arrow} alt="Flèche des randonnées alternatives" className="icon rotate-0" />

    }
    

    if(isMobile()) {
      return (
        <>
          <h2 className="mt-3">Randonnées alternatives</h2>
          {children}
          <p>Autres randonnées croisant les mêmes sentiers, de différentes difficultés (selon les préférences !).</p>
        </>
      );
    } else {
        return (
          <div className={styles.hikeCollapse}>
            <div className={styles.collapseHeader}>
              <Button
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
              >
                <span>Randonnées alternatives</span>
                <ArrowButton />
              </Button>
            </div>
            <Collapse in={open}>
              <div className={styles.collapseBody}>
                {children}
              </div>
            </Collapse>
          </div>
        );
    }  
  }