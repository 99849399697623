import React from "react";
import { Link } from "gatsby";
import { Col, Row, Container } from "react-bootstrap";

import HikeCard from "../HikeCard";
import Carousel from "../../UI/Carousel/Carousel";

type HikesAroundProps = {
  hikes: Queries.AllHikesListFragment;
  parkTitle: string | null | undefined;
  parkFullTitle: string | null | undefined;
  hikeUrbanAreaTitle: string | string[] | null | undefined;
  hikeTitle: string | null | undefined;
  destinationUrl: string | null;
  parkGrammarAgreement: string | undefined;
};

export default function HikesAround(props: HikesAroundProps) {
  const HikesCarousel = (props: { hikes: Queries.AllHikesListFragment }) => {
    if (props.hikes.nodes) {
      const listOfCards = props.hikes.nodes?.map((hike) => (
        <HikeCard {...(hike as Queries.HikesListFragment)} key={hike?.id} />
      ));

      const carouselSettings = {
        responsive: {
          breakpoint1500: {
            slidesToShow: 4.2,
          },
          breakpoint1300: {
            slidesToShow: 3.5,
          },
          breakpoint1024: {
            slidesToShow: 2.7,
          },
          breakpoint768: {
            slidesToShow: 2,
          },
          breakpoint560: {
            slidesToShow: 1.5,
          },
          breakpoint480: {
            slidesToShow: 1.1,
          },
        },
        className: "carousel-settings",
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: false,
      };

      const carouselProps = { listOfCards, carouselSettings };

      return <Carousel {...carouselProps} />;
    }
    return null;
  };

  // Get the destination of the park or UrbanArea associated with the hike
  const DestinationLink = () => {
    if (props.parkFullTitle && props.destinationUrl) {
      return <Link to={props.destinationUrl}>→ {props?.parkFullTitle}</Link>;
    } else if (props.hikeUrbanAreaTitle && props.destinationUrl) {
      return (
        <Link to={props.destinationUrl}>→ {props?.hikeUrbanAreaTitle}</Link>
      );
    }
    return null;
  };

  if (props.hikes.nodes.length > 0) {
    return (
      <Container fluid>
        <Row className="mx-2 green">
          <Col className="py-3">
            <h2>Autres randonnées à proximité</h2>
            <p>
              Retrouvez d'autres belles randonnées à réaliser autour de «{" "}
              {props.hikeTitle} »{" "}
              {props?.parkGrammarAgreement}{" "}
              {props?.parkTitle}.
            </p>
            <HikesCarousel hikes={props.hikes} />
            <DestinationLink />
          </Col>
        </Row>
      </Container>
    );
  }
  return null;
}
