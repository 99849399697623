import React from "react";
import { PageProps, HeadProps, graphql } from "gatsby";
import { Col, Row, Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

import Meta from "../components/Layout/head";
import Layout from "../components/Layout/layout";
import countryDestination from "../components/country/country-destination";
import IsMobile from "../components/Layout/IsMobile";

import HikeCover from "../components/Hike/HikeTemplate/HikeCover";
import HikeInfo from "../components/Hike/HikeTemplate/HikeInfo";
import HikeBannerButtons from "../components/Hike/HikeTemplate/HikeBannerButtons";
import HikesAlternatives from "../components/Hike/HikeTemplate/HikesAlternatives";
import HikesAround from "../components/Hike/HikeTemplate/HikesAround";
import HikeAccommodations from "../components/Hike/HikeTemplate/HikeAccommodations";
import HikeMapbox from "../components/Hike/HikeMapbox";
import HikeParking from "../components/Hike/HikeTemplate/HikeParking";
import HikeImprovement from "../components/Hike/HikeTemplate/HikeImprovement";
import GrammarAgreement from "../components/Park/GrammarAgreement";

import * as styles from "./Hike.module.scss";

export const Head = ({ data }: HeadProps<Queries.HikeTemplateQuery>) => {
  const hike = data.contentfulHike;

  const hikeTitle = hike?.title ? hike.title : null;
  const parkFullTitle = hike?.park?.fullTitle ? hike.park.fullTitle : null;
  const hikeUrbanAreaTitle = hike?.hikeUrbanArea
    ? `autour de « ${hike?.hikeUrbanArea[0]?.title} »`
    : `au cœur de la France`;

  const country = countryDestination(hike?.countryCode);
  const hikeSlug = hike?.slug;
  const pathname = `/${country}/randonnees/${hikeSlug}/`;

  const metaDesc = () => {
    if (parkFullTitle) {
      return `Découvrez la randonnée « ${hikeTitle} » au cœur du « ${parkFullTitle} ». Retrouvez toutes les informations utiles : Dénivelé, difficulté…`;
    } else if (hikeUrbanAreaTitle) {
      return `Découvrez la randonnée « ${hikeTitle} » ${hikeUrbanAreaTitle}. Retrouvez toutes les informations utiles : Dénivelé, difficulté…`;
    } else {
      return `Découvrez la randonnée « ${hikeTitle} » et retrouvez toutes les informations utiles : Dénivelé, difficulté, durée…`;
    }
  };

  return (
    <Meta
      title={`${hikeTitle} - Randonnée & Guide`}
      description={metaDesc()}
      lang="fr"
      media={`https:${hike?.cover?.file?.url}`}
      pathname={pathname}
    >
    </Meta>
  );
};

export default function HikeTemplate({
  data,
  location,
}: PageProps<Queries.HikeTemplateQuery>) {
  const [padding, setPadding] = React.useState(40);

  function HikeDescription(props: {
    description: string | null | undefined;
    title: string | null | undefined;
  }) {
    if (props.description) {
      return (
        <>
          <h2>
            Description <span className="infoTitleMd">- {props.title}</span>
          </h2>
          <ReactMarkdown>{props.description}</ReactMarkdown>
        </>
      );
    }
    return null;
  }

  // Globals data variables
  const hike = data.contentfulHike;
  const allHikes = data.allContentfulHike;

  // Hike variables
  const hikeTitle = hike?.title ? hike.title : null;
  const hikeDescription = hike?.description?.description;

  const country = countryDestination(hike?.countryCode);
  const hikeSlug = hike?.slug;

  const hikeAlternatives = hike?.alternatives ? hike?.alternatives : null;
  const parkingCoordinates = {
    parkingLatitude: hike?.parkingLocation?.lat
      ? hike.parkingLocation.lat
      : null,
    parkingLongitude: hike?.parkingLocation?.lon
      ? hike.parkingLocation.lon
      : null,
  };
  const parkingLabel = hike?.parkingLabel?.parkingLabel
    ? hike.parkingLabel.parkingLabel
    : null;
  const gpxPath = hike?.gpxPath?.file?.url ? hike.gpxPath.file.url : undefined;

  React.useEffect(() => {
    if (hikeAlternatives) {
      setPadding(300);
    }
  }, [hikeAlternatives]);

  const parkFullTitle = hike?.park?.fullTitle;
  const hikeUrbanAreaTitle = hike?.hikeUrbanArea
    ? hike?.hikeUrbanArea[0]?.title
    : null;

  const parkTitle = hike?.park?.title;
  const parkGrammarAgreement = GrammarAgreement(hike?.park?.grammarAgreement);

  const destinationUrl = () => {
    if (hike?.park) {
      return `/${country}/${hike.park.slug}/`;
    } else if (hike?.hikeUrbanArea) {
      return `/${country}/randonnees/${hike.hikeUrbanArea[0]?.slug}/`;
    }
    return null;
  };

  const DestinationCoordinates = () => {
    const coordinates = {
      latitude: 0,
      longitude: 0,
    };

    if (hike?.park) {
      if (hike.park.location?.lat && hike.park.location?.lon) {
        coordinates.latitude = hike.park.location.lat;
        coordinates.longitude = hike.park.location.lon;
      }
    } else if (hike?.hikeUrbanArea) {
      if (
        hike.hikeUrbanArea[0]?.location?.lat &&
        hike.hikeUrbanArea[0]?.location?.lon
      ) {
        coordinates.latitude = hike.hikeUrbanArea[0].location.lat;
        coordinates.longitude = hike.hikeUrbanArea[0].location.lon;
      }
    }
    return coordinates;
  };

  return (
    <Layout>
      <Container fluid>
        <Row className={`g-0 ${styles.hikePage}`}>
          <Col
            sm="12"
            md="6"
            lg="5"
            data-bs-spy="scroll"
            tabIndex={0}
            className={`${styles.leftColumn} green`}
          >
            <HikeCover hike={hike} location={location} />

            <HikeBannerButtons location={location} hike={hike} />

            <HikeInfo hike={hike} />

            <Container className="gx-4">
              <Row>
                <Col md="12">
                  <HikeDescription
                    description={hikeDescription}
                    title={hikeTitle}
                  />
                </Col>
              </Row>
              <Row>
                {!IsMobile() && (
                  <HikeParking
                    parkingCoordinates={parkingCoordinates}
                    parkingLabel={parkingLabel}
                    title={hikeTitle}
                    className="btn btn-orange btn-lg text-white text-center"
                  />
                )}
                {!IsMobile() && <HikeAccommodations slug={hikeSlug} title={hikeTitle} />}
                {!IsMobile() && <HikeImprovement />}
              </Row>
            </Container>
          </Col>

          <Col sm="12" md="6" lg="7" className={styles.rightColumn}>
            <HikeMapbox
              latitude={DestinationCoordinates().latitude}
              longitude={DestinationCoordinates().longitude}
              gpxPath={gpxPath}
              padding={padding}
              className="position-fixed"
            />
            <HikesAlternatives alternatives={hikeAlternatives} {...hike} />
            {IsMobile() && <HikeAccommodations slug={hikeSlug} />}
            {IsMobile() && (
              <HikeParking
                parkingCoordinates={parkingCoordinates}
                parkingLabel={parkingLabel}
                title={hikeTitle}
                className="btn btn-orange btn-lg text-white text-center"
              />
            )}
            {IsMobile() && <HikeImprovement />}
          </Col>
        </Row>
      </Container>

      <HikesAround
        hikes={allHikes}
        destinationUrl={destinationUrl()}
        hikeUrbanAreaTitle={hikeUrbanAreaTitle}
        parkTitle={parkTitle}
        parkFullTitle={parkFullTitle}
        hikeTitle={hikeTitle}
        parkGrammarAgreement={parkGrammarAgreement?.grammarAgreementIn}
      />
    </Layout>
  );
}

export const HikeTemplateQuery = graphql`
  query HikeTemplate(
    $hikeSlug: String!
    $hikeMinLat: Float!
    $hikeMaxLat: Float!
    $hikeMinLng: Float!
    $hikeMaxLng: Float!
    $hikeAlternatives: [String]!
  ) {
    contentfulHike(slug: { eq: $hikeSlug }, node_locale: { eq: "fr" }) {
      ...OneHike
    }
    allContentfulHike(
      filter: {
        location: {
          lat: { gte: $hikeMinLat, lte: $hikeMaxLat }
          lon: { gte: $hikeMinLng, lte: $hikeMaxLng }
        }
        slug: { nin: $hikeAlternatives }
        node_locale: { eq: "fr" }
      }
      limit: 20
    ) {
      ...AllHikesList
    }
  }
`;
