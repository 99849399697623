import React from "react";
import { PageProps } from "gatsby";
import {
  Modal,
  Row,
  Col,
  FormControl,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  PinterestShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  PinterestIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { StaticImage } from "gatsby-plugin-image";
import icon_copy from "../../images/icons/icon_copy.svg";

const UpdatingPopover = React.forwardRef(
  ({ popper, children, show: _, ...props }, ref) => {
    React.useEffect(() => {
      popper.scheduleUpdate();
    }, [children, popper]);

    return (
      <Popover ref={ref} body {...props}>
        {children}
      </Popover>
    );
  }
);

const iconSize = 55;
const placement = "bottom";

const copiedText = `C'est copié !`;
const toCopyText = `Copier dans le presse papier`;

// interface Modal {
//   location: PageProps["location"],
//   facebookTitle: string,
//   twitterTitle: string,
//   emailSubject: string,
//   emailBody: string,
//   linkedInTitle: string
// }

export default function ShareModal(props) {
  // const [show, setShow] = React.useState(false);
  const [copyText, setCopyText] = React.useState(toCopyText);

  const url = props.location.href;

const { facebookTitle, twitterTitle, emailSubject, emailBody, linkedInTitle } = props;

  return (
    <Modal show={props.showModal} onHide={props.closeModal}>
      <Modal.Header className="bg-mint" closeButton>
        <Modal.Title className="green">Partager</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="3" xs="6" className="text-center">
            <div className="d-inline-block py-2">
              <FacebookShareButton
                url={url}
                quote={facebookTitle}
                className="share-button"
              >
                <FacebookIcon size={iconSize} round />
              </FacebookShareButton>
              <p className="my-0">Facebook</p>
            </div>
          </Col>
          <Col md="3" xs="6" className="text-center">
            <div className="d-inline-block py-2">
              <TwitterShareButton
                url={url}
                title={twitterTitle}
                className="share-button"
                via="rheaparks_app"
              >
                <TwitterIcon size={iconSize} round />
              </TwitterShareButton>
              <p className="my-0">Twitter</p>
            </div>
          </Col>
          <Col md="3" xs="6" className="text-center">
            <div className="d-inline-block py-2">
              <FacebookMessengerShareButton url={url} className="share-button">
                <FacebookMessengerIcon size={iconSize} round />
              </FacebookMessengerShareButton>

              <p className="my-0">Messenger</p>
            </div>
          </Col>
          <Col md="3" xs="6" className="text-center">
            <div className="d-inline-block py-2">
              <EmailShareButton
                url={url}
                className="share-button"
                subject={emailSubject}
                body={emailBody}
              >
                <EmailIcon size={iconSize} round />
              </EmailShareButton>
              <p className="my-0">Email</p>
            </div>
          </Col>
          <Col md="3" xs="6" className="text-center">
            <div className="d-inline-block py-2">
              <PinterestShareButton
                url={url}
                media="/favicon.ico"
                className="share-button"
              >
                <PinterestIcon size={iconSize} round />
              </PinterestShareButton>
              <p className="my-0">Pinterest</p>
            </div>
          </Col>
          <Col md="3" xs="6" className="text-center">
            <div className="d-inline-block py-2">
              <LinkedinShareButton
                url={url}
                title={linkedInTitle}
                source="Rheaparks"
                className="share-button"
              >
                <LinkedinIcon size={iconSize} round />
              </LinkedinShareButton>
              <p className="my-0">LinkedIn</p>
            </div>
          </Col>
        </Row>
        <div className="mt-3 mb-4">
          <div className="h5 green">Partager le lien :</div>

          <form onMouseLeave={() => setCopyText(toCopyText)}>
            <div
              onClick={() => setCopyText(copiedText)}
              onKeyDown={() => setCopyText(copiedText)}
              role="button"
              tabIndex={0}
            >
              <FormGroup>
                <OverlayTrigger
                  key={placement}
                  placement={placement}
                  overlay={
                    <UpdatingPopover id="popover-contained">
                      {copyText}
                    </UpdatingPopover>
                  }
                >
                  <InputGroup
                    onClick={() => {
                      navigator.clipboard.writeText(url);
                    }}
                    className="copy-to-clipboard"
                  >
                    <FormControl type="text" defaultValue={url} disabled />
                    <InputGroup.Text>
                      <img
                        src={icon_copy}
                        alt="Icône copier/coller"
                        width={20}
                        height={20}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </OverlayTrigger>
              </FormGroup>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
