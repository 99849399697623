import React, { CSSProperties, ReactNode } from "react";
import { Button } from "react-bootstrap";

interface ProjectButton {
  children: ReactNode,
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  href?: string;
  size?: "sm" | "lg" | undefined,
  cssStyle?: CSSProperties | undefined
  type?: 'submit' | 'reset' | 'button' | undefined
}

function ProjectButton(props: ProjectButton) {
  return (
    <Button
      type={props.type}
      className={props.className}
      onClick={props.onClick}
      href={props.href}
      size={props.size}
      style={props.cssStyle}
    >
      {props.children}
    </Button>
  );
}

export default ProjectButton;
