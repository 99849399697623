import React, { useCallback, useEffect, useState, useRef } from "react";
import MapGL, { NavigationControl, Source, Layer, MapRef } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import gpxParser from "gpxparser";
import bbox from "@turf/bbox";
import { Modal } from "react-bootstrap";
import IsMobile from "../Layout/IsMobile";
import { GeoJsonGeometryTypes } from "geojson";
import MapboxStaticImage from "../Mapbox/MapboxStaticImage";

// https://visgl.github.io/react-map-gl/examples/geojson
// https://docs.mapbox.com/mapbox-gl-js/api/events/

interface Props {
  latitude: number | undefined;
  longitude: number | undefined;
  gpxPath: string | undefined;
  padding: number;
  className: string;
}

export default function HikeMapbox(props: Props) {
  const [show, setShow] = useState(false);
  const [geoJson, setGeoJson] = useState<GeoJsonGeometryTypes | null>(null);
  const { latitude, longitude, gpxPath, padding } = props;

  const mapRef = useRef<MapRef | null>(null);
  let setPadding = { top: 40, bottom: padding, left: 40, right: 40 };

  // Call and read the GPX file, return it as a bunch of text
  useEffect(() => {
    const gpxPathUrl = `https:${gpxPath}`;

    // Simple GET request using fetch
    // Take the text and parse it as geoJSON
    const fetchData = async () => {
      try {
        const data = await fetch(gpxPathUrl);
        const text = await data.text();
        let gpx: any = ({} = new gpxParser());

        gpx.parse(text);

        const geoJSON = gpx.toGeoJSON();
        setGeoJson(geoJSON);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [gpxPath]);

  // Wait the geoJson and get the minLon, minLat, maxLon, maxLat to zoom on the hike
  // See : https://visgl.github.io/react-map-gl/examples/zoom-to-bounds/
  const setCamera = useCallback(() => {
    if (geoJson) {
      const [minLng, minLat, maxLng, maxLat] = bbox(geoJson);

      mapRef.current?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: setPadding,
          duration: 0,
        }
      );
    }
  }, [geoJson, mapRef, setPadding]);

  // After render and getting mapRef zoom on the map
  const onRef = (mapNode: MapRef) => {
    if (mapNode) {
      // re-render on changes
      mapRef.current = mapNode;
      setCamera();
    }
  };

  // Return the GPX trace in Mapbox
  function ShowGpxFile() {
    // Choose the style of the GPX trace
    const layerStyle = {
      id: "contours",
      type: "line" as "line",
      source: "contours",
      paint: {
        "line-color": "#005A5A",
        "line-width": 3,
      },
    };

    if (geoJson) {
      return (
        <>
          {
            // Return the React Mapbox GL code to show the GPX file on the map
            <Source type="geojson" data={geoJson}>
              <Layer {...layerStyle} />
            </Source>
          }
        </>
      );
    }
    return null;
  }

  function MapModal() {
    return (
      <Modal show={show} onHide={() => setShow(false)} fullscreen={true}>
        <Modal.Header className="close-modal" closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-fullscreen-body">
            <MapGL
              ref={onRef}
              initialViewState={{
                latitude: latitude,
                longitude: longitude,
                zoom: 8,
              }}
              style={{ width: "100%", height: "100%" }}
              mapStyle="mapbox://styles/rheaparks/cl0ck8gkr001314nkcl4hjvuk"
              mapboxAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
            >
              <ShowGpxFile />
            </MapGL>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  // If mobile return a locked map with modal
  if (IsMobile()) {
    // PROBLEM: Calling multiple times the MapboxStaticImage query when modifiying the window size
    if (latitude && longitude && geoJson) {
      return (
        <>
          <h2>Carte et itinéraire</h2>
          <div className="mapbox-mobile">
            <MapboxStaticImage
              geoJsonMap={{
                geoJsonData: geoJson,
                padding: { top: 40, bottom: 40, left: 40, right: 40 },
              }}
              onClick={() => setShow(true)}
              alt={`Tracé GPX de la randonnée`}
              tapIcon={true}
              width={500}
              height={320}
            />
          </div>
          <div className="modal-fullscreen">
            <MapModal />
          </div>
        </>
      );
    }
    return null;
  }

  // If desktop return a staticImage with map
  return (
    <>
      {!show && (
        <MapboxStaticImage
          geoJsonMap={{
            geoJsonData: geoJson,
            padding: setPadding,
          }}
          alt={`Tracé GPX de la randonnée`}
          onClick={() => setShow(true)}
          tapIcon={true}
          width={800}
          height={555}
        />
      )}
      {show && geoJson && (
        <MapGL
          ref={onRef}
          initialViewState={{
            latitude: props.latitude,
            longitude: props.longitude,
            zoom: 8,
          }}
          style={{ width: "100%", height: "100%" }}
          mapStyle="mapbox://styles/rheaparks/cl0ck8gkr001314nkcl4hjvuk"
          mapboxAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
        >
          <NavigationControl />
          <ShowGpxFile />
        </MapGL>
      )}
    </>
  );
}
