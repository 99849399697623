import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Col, Row } from "react-bootstrap";
import hikeDifficulty from "./HikeDifficulty";
import HikeDuration from "./HikeDuration";
import countryDestination from "../country/country-destination";
import hikeRank from "./HikeRank";

import * as styles from "./HikeAltCard.module.scss";

import illustration from "../../images/default-illustration.svg";
import elevationImg from "../../images/icons/icon_elevation_light.svg";
import distanceImg from "../../images/icons/icon_distance_light.svg";
import dureeImg from "../../images/icons/icon_duree_light.svg";

export default function HikeAltCard(data: Queries.OneAlternativeFragment) {
  if (data) {
    const GetCover = (props: {
      data: Queries.OneAlternativeFragment;
      alt: string;
    }) => {
      const imgCover = getImage(props?.data.cover);
      if (imgCover) {
        return (
          <GatsbyImage image={imgCover} className={styles.img} alt={alt} />
        );
      } else {
        return (
          <img
            src={illustration}
            alt="Illustration paysage de randonnée"
            className={`${styles.img} ${styles.defaultImg} `}
          />
        );
      }
    };

    // Création des variables Contentful appelé via les Props et mise en page
    const title = data.title;
    const slug = data.slug;
    const imgDescString = data?.cover?.description;
    const imgDescJson = imgDescString ? JSON.parse(imgDescString) : "";
    const instagramHandle = imgDescJson.ig_handle
      ? `de ${imgDescJson.ig_handle.replace(/</g, "\\u003c")}`
      : "";
    const alt = imgDescJson.ig_handle
      ? `Photographie ${instagramHandle} sur la randonnée "${title}"`
      : `Photographie de la randonnée "${title}"`;

    const difficultyNumber = data.difficultyLevel;
    const difficulty = difficultyNumber
      ? hikeDifficulty(difficultyNumber)
      : null;
    const difficultyColor = difficulty ? difficulty?.color : "";

    const durationNumber = data.duration;
    const duration = durationNumber ? new HikeDuration(durationNumber) : null;
    const elevation = data.elevation + " m";
    const distance = data.distance?.toFixed(1) + " km";

    const country = countryDestination(data.countryCode);

    const rank = data.rank ? hikeRank(data.rank) : null;
    const rankImg = rank?.img;
    const rankAlt = rank?.alt;

    return (
      <Link to={`/${country}/randonnee/${slug}/`}>
        <Row className={`card ${styles.hikeAltCard}`}>
          <Col xs="12" className="position-relative g-0">
            <div className={styles.cover}>
              <GetCover data={data} alt={alt} />
              <div
                className={`${styles.difficulty} ${styles.absoluteDifficulty} ${styles[difficultyColor]}`}
              >
                <span>{difficulty?.level}</span>
              </div>
              <div
                className={`d-inline-flex align-items-center ${styles.ranking}`}
              >
                <div className="icon me-1">
                  {rankImg && (
                    <img src={rankImg} alt={rankAlt} className='icon' />
                  )}
                </div>
                <h4 className="mb-0">{title}</h4>
              </div>
            </div>
            <Row className={styles.info}>
              <Col xs="4" sm="4" md="12" lg="4" className={styles.colInfo}>
                <img
                  src={elevationImg}
                  alt="Galerie d'images"
                  className="icon"
                />
                <span>{elevation}</span>
              </Col>
              <Col xs="4" sm="4" md="12" lg="4" className={styles.colInfo}>
                <img
                  src={distanceImg}
                  alt="Galerie d'images"
                  className="icon"
                />
                <span>{distance}</span>
              </Col>
              <Col xs="4" sm="4" md="12" lg="4" className={styles.colInfo}>
                <img src={dureeImg} alt="Galerie d'images" className="icon" />
                <span>{duration?.formattedValue}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Link>
    );
  }

  return null;
}
