import React from "react";
import HikeAltCard from "../HikeAltCard";
import AlternativeHikeAccordion from "../HikeAltAccordion";
import Carousel from "../../UI/Carousel/Carousel";

export default function HikesAlternatives(props: { alternatives: Queries.OneHikeFragment['alternatives'] }) {
    const alternatives = props.alternatives;

    if(alternatives) {
      const listOfCards = alternatives?.flatMap((alternative) => alternative ? [alternative] : []) // Filter null elements
      .map((alternative) => (
        <HikeAltCard {...alternative as Queries.OneAlternativeFragment} key={alternative?.id} />
      )) 
      
      const carouselSettings = {
        responsive: {
          breakpoint2400: {
            slidesToShow: 3.5,
          },
          breakpoint1500: {
            slidesToShow: 3
          },
          breakpoint1300: {
            slidesToShow: 2.5
          },
          breakpoint1024: {
            slidesToShow: 2.2
          }
        },
        className: "carousel-settings",
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: false
      }

      const carouselProps = { listOfCards, carouselSettings }

      return (
        <AlternativeHikeAccordion>
          <Carousel {...carouselProps} />
        </AlternativeHikeAccordion>
      );
    }
    return null;
}