import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import PopoverStickOnHover from "../../popover-stick-onhover";
import hikeRankOverlay from "../HikeRankOverlay";
import hikeDuration from "../HikeDuration";
import hikeDifficulty from "../HikeDifficulty";
import hikeType from "../HikeType";
import hikeRank from "../HikeRank";
import hikeDogs from "../HikeDogs";

import * as styles from "./HikeInfo.module.scss";

import elevation_svg from "../../../images/icons/icon_elevation.svg";
import distance_svg from "../../../images/icons/icon_distance.svg";
import duree_svg from "../../../images/icons/icon_duree.svg";

export default function HikeInfo(props: {
  hike: Queries.OneHikeFragment | null;
}) {
  const hike = props.hike;

  if (hike) {
    const distance = hike?.distance?.toFixed(1) + " km";
    const elevation = hike?.elevation + " m";

    const duration = hike?.duration ? new hikeDuration(hike?.duration) : null;

    const difficulty =
      typeof hike?.difficultyLevel === "number"
        ? hikeDifficulty(hike.difficultyLevel)
        : null;
    const difficultyColor = difficulty ? difficulty.color : "";

    const journeyType =
      typeof hike?.journeyType === "number" ? hikeType(hike.journeyType) : null;
    const typeImg = journeyType?.img;
    const typeText = journeyType?.title;
    const typeAlt = journeyType?.alt;

    const dogsRules =
      typeof hike?.dogs === "number" && typeof hike?.dogs !== undefined
        ? hikeDogs(hike?.dogs)
        : null;
    const dogsImg = dogsRules?.img;
    const dogsTitle = dogsRules?.title;
    const dogsAlt = dogsRules?.alt;

    const rank = typeof hike?.rank === "number" ? hikeRank(hike.rank) : null;
    const rankImg = rank?.img;
    const rankTitle = rank?.title;
    const rankAlt = rank?.alt;

    return (
      <Container className="mb-3 gx-4">
        <Row className="g-2">
          <Col lg="3" md="4" sm="4" xs="6" className="pt-3">
            <div className={styles.info}>
              <p className={styles.info__title}>Difficulté</p>
              <div
                className={`${styles.difficulty} ${styles[difficultyColor]}`}
              >
                <p>{difficulty?.level}</p>
              </div>
            </div>
          </Col>

          <Col lg="3" md="4" sm="4" xs="6" className="pt-3">
            <div className={styles.info}>
              <p className={styles.info__title}>Dénivelé</p>
              <img
                src={elevation_svg}
                alt="Dénivelé de la randonnée"
                className={styles.icon}
              />
              <p>{elevation}</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="4" xs="6" className="pt-3">
            <div className={styles.info}>
              <p className={styles.info__title}>Distance</p>
              <img
                src={distance_svg}
                alt="Distance de la randonnée"
                className={styles.icon}
              />
              <p>{distance}</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="4" xs="6" className="pt-3">
            <div className={styles.info}>
              <p className={styles.info__title}>Durée</p>
              <img
                src={duree_svg}
                alt="Distance de la randonnée"
                className={styles.icon}
              />
              <p>{duration?.formattedValue}</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="4" xs="6" className="pt-3">
            <PopoverStickOnHover
              component={hikeRankOverlay()}
              placement="right"
            >
              <div className={styles.info}>
                <p className={styles.info__title}>Classement</p>
                {rankImg && (
                  <img src={rankImg} alt={rankAlt} className={styles.icon} />
                )}
                {rankTitle && <p>{rankTitle}</p>}
              </div>
            </PopoverStickOnHover>
          </Col>

          <Col lg="3" md="4" sm="4" xs="6" className="pt-3">
            <div className={styles.info}>
              <p className={styles.info__title}>Type</p>
              {typeImg && (
                <img src={typeImg} alt={typeAlt} className={styles.icon} />
              )}
              {typeText && <p>{typeText}</p>}
            </div>
          </Col>

          <Col lg="3" md="4" sm="4" xs="6" className="pt-3">
            <div className={styles.info}>
              <p className={styles.info__title}>Chiens</p>
              {dogsImg && (
                <img src={dogsImg} alt={dogsAlt} className={styles.icon} />
              )}
              {dogsTitle && <p>{dogsTitle}</p>}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  return null;
}
