// extracted by mini-css-extract-plugin
export var absoluteDifficulty = "HikeAltCard-module--absoluteDifficulty--e306b";
export var bgDarkGreen = "HikeAltCard-module--bg-dark-green--a628d";
export var bgDarkGreen01 = "HikeAltCard-module--bg-dark-green-01--6c6a9";
export var bgGradientGreen = "HikeAltCard-module--bg-gradient-green--ab6b8";
export var bgGradientLyellow = "HikeAltCard-module--bgGradientLyellow--b68c5";
export var bgGreen = "HikeAltCard-module--bg-green--5a5e8";
export var bgGrey = "HikeAltCard-module--bg-grey--b8372";
export var bgLightBlue = "HikeAltCard-module--bg-light-blue--e64ac";
export var bgLightGreen = "HikeAltCard-module--bg-light-green--f1c03";
export var bgLightGreenColor = "HikeAltCard-module--bg-light-green-color--8dc35";
export var bgLightGrey = "HikeAltCard-module--bg-light-grey--3d4fa";
export var bgLightYellow = "HikeAltCard-module--bg-light-yellow--a52b6";
export var bgMint = "HikeAltCard-module--bg-mint--e820b";
export var bgOrange = "HikeAltCard-module--bg-orange--1a58b";
export var card = "HikeAltCard-module--card--71c43";
export var colInfo = "HikeAltCard-module--colInfo--bf316";
export var cover = "HikeAltCard-module--cover--947ec";
export var defaultImg = "HikeAltCard-module--default-img--31a1d";
export var difficulty = "HikeAltCard-module--difficulty--c1de3";
export var easyHike = "HikeAltCard-module--easy-hike--41a23";
export var green = "HikeAltCard-module--green--63b38";
export var grey = "HikeAltCard-module--grey--b2e7c";
export var hardHike = "HikeAltCard-module--hard-hike--42f67";
export var hikeAltCard = "HikeAltCard-module--hike-alt-card--a1dbb";
export var img = "HikeAltCard-module--img--48496";
export var info = "HikeAltCard-module--info--7937d";
export var lightBlue = "HikeAltCard-module--light-blue--d1945";
export var lightGreen = "HikeAltCard-module--light-green--0d053";
export var lightYellow = "HikeAltCard-module--light-yellow--d4452";
export var mediumHike = "HikeAltCard-module--medium-hike--e8af1";
export var mint = "HikeAltCard-module--mint--38ead";
export var orange = "HikeAltCard-module--orange--806c7";
export var overlayLight = "HikeAltCard-module--overlay-light--c5f40";
export var popoverHeader = "HikeAltCard-module--popover-header--44c17";
export var ranking = "HikeAltCard-module--ranking--4d23e";
export var unknownHike = "HikeAltCard-module--unknown-hike--f66ab";
export var veryHardHike = "HikeAltCard-module--very-hard-hike--197af";