import React from "react";
import { PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Breadcrumb from "../../Layout/breadcrumb";
import countryDestination from "../../country/country-destination";
import GalleryComponent from "../../UI/Gallery/Gallery";

import illustration_svg from "../../../images/default-illustration.svg";

import * as styles from "./HikeCover.module.scss";

export default function HikeCover(props: {
  hike: Queries.OneHikeFragment | null;
  location: PageProps["location"];
}) {
  const hike = props.hike;

  const imgDescString = hike?.cover?.description;
  const imgDescJson = imgDescString ? JSON.parse(imgDescString) : false;
  const instagramHandle = imgDescJson.ig_handle
    ? `de ${imgDescJson.ig_handle.replace(/</g, "\\u003c")}`
    : false;
  const alt = imgDescJson.ig_handle
    ? `Photographie ${instagramHandle} sur la randonnée "${hike?.title}"`
    : `Photographie de la randonnée "${hike?.title}"`;

  // Breadcrumb
  const country = countryDestination(hike?.countryCode);

  const parkTitle = hike?.park?.title;
  const parkSlug = hike?.park?.slug;
  const urbanAreaTitle = hike?.hikeUrbanArea
    ? hike?.hikeUrbanArea[0]?.title
    : null;
  const urbanAreaSlug = hike?.hikeUrbanArea
    ? hike?.hikeUrbanArea[0]?.slug
    : null;
  const hikeTitle = hike?.title;
  const hikeSlug = hike?.slug;

  const breadcrumb = {
    titleDest: `${parkTitle || urbanAreaTitle || ""}`,
    pathDest: `/${country}/${parkSlug || urbanAreaSlug || ""}/`,
    pathHike: `/${country}/randonnee/${hikeSlug}/`,
    titleHike: hikeTitle,
  };
  const breadcrumbArray = [
    [breadcrumb.pathDest, breadcrumb.titleDest],
    [breadcrumb.pathHike, breadcrumb.titleHike],
  ];

  const Cover = () => {
    const cover = hike?.cover;
    const hasCover = cover ? getImage(cover) : null;

    if (hasCover) {
      return (
        <div className="cursor-pointer">
          <GatsbyImage
            image={hasCover}
            className={styles.cover}
            loading="eager"
            alt={alt}
          />
        </div>
      );
    }
    return (
      <img
        src={illustration_svg}
        alt="Illustration paysage de randonnée"
        className={styles.cover}
      />
    );
  };

  const DestinationTitle = () => {
    const hasParkTitle = hike?.park ? hike.park.title : null;
    const hasHikeUrbanAreaTitle =
      hike?.hikeUrbanArea && hike.hikeUrbanArea[0]?.title
        ? hike.hikeUrbanArea[0].title
        : null;
    const hasDestinationTitle = hasParkTitle
      ? hasParkTitle
      : hasHikeUrbanAreaTitle;
    if (hasDestinationTitle) {
      return (
        <div className={styles.destinationTitle}>
          <span> - </span>
          <h2>{hasDestinationTitle}</h2>
        </div>
      );
    }
    return null;
  };

  const Gallery = (props: { children: JSX.Element }) => {
    const hasGalleryImages = hike?.gallery;
    if (hasGalleryImages) {
      return <GalleryComponent data={hike}>{props.children}</GalleryComponent>;
    }
    return props.children;
  };

  return (
    <Gallery>
      <div className={styles.hikeCover}>
        <Cover />
        <div className={`${styles.breadcrumb} breadcrumb__light`}>
          <Breadcrumb location={props.location} itemsArray={breadcrumbArray} />
        </div>
        <div className={styles.title}>
          <h1>
            <span className="infoTitleLg">Randonnée </span>
            <br />
            {hike?.title}
          </h1>
          <DestinationTitle />
        </div>
      </div>
    </Gallery>
  );
}
